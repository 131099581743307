
import {
    defineComponent, PropType,
    ref, watch
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertySubRouter, propertyBaseRouter } from '@/router';
import { access, community } from '@/data';
import addButton from '@/components/common/add-button/index.vue';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import { list } from '@/components/common/list';
import { setPinCard } from '@/components/view/pm/set-resident';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import { limitErrorImg } from '@/methods/basicFun';
import EditAccessibleFloors from './components/edit-accessible-floors.vue';
import { initInfo, initSetPinCard, getDeviceData } from './info';

const { repeatLabels } = access;
export default defineComponent({
    components: {
        propertyBreadCrumb,
        secondaryNav,
        addButton,
        setPinCard,
        list,
        deviceIcon,
        deviceStatus,
        EditAccessibleFloors
    },
    props: {
        id: {
            type: String,
            required: true
        },
        type: {
            type: String as PropType< ProjectType >,
            default: 'community'
        }
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };
        console.log(1);
        console.log(1);

        const breadHeaders = ref([
            {
                label: WordList.RDeviceResidents,
                path: `/${propertyBaseRouter}/${propertySubRouter.resident}`
            },
            {
                label: WordList.ProperAllTextView
            }
        ]);

        watch(community.ProjectType, () => {
            if (community.ProjectType.value === 'office') {
                breadHeaders.value[0].label = WordList.ProperAllTextPeople;
            }
        }, {
            immediate: true
        });

        const goEditInfo = () => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.residentSetInfo}?id=${props.id}&type=${props.type}`);
        };

        const {
            info,
            role,
            roomId,
            secNavs,
            activeKey,
            changeActiveKey,
            getAccessInfo,
            pinHeader,
            cardHeader,
            faceHeader,
            pinData,
            cardData,
            faceData,
            pinAction,
            cardAction,
            faceAction,
            updateAccessType,
            resetPw,
            accessGroupHeaders,
            accessGroupEdit,
            accessGroupData,
            selectAccessData,
            selfAccessHeaders,
            selfAccessEdit,
            fileEle,
            addFace,
            chooseFace,
            isShowSelfAccess,
            officeData,
            isShowResetBtn,
            floor,
            AccessFloor
        } = initInfo(props.id, props.type);

        const {
            code,
            type: setPinCardType,
            isShow,
            setPinCardId,
            addCard,
            addPin,
            editPin,
            editCard
        } = initSetPinCard(props.id);
        const addAccess = () => {
            if (activeKey.value === 'pin') {
                addPin();
            } else if (activeKey.value === 'card') {
                addCard();
            } else {
                addFace();
            }
        };

        const {
            devices,
            deviceHeader,
            updateDevice
        } = getDeviceData();

        // 6.5.3办公梯控
        const isShowEditAccessibleFloors = ref(false);

        return {
            repeatLabels,
            breadHeaders,
            info,
            role,
            roomId,
            secNavs,
            activeKey,
            changeActiveKey,
            getAccessInfo,
            pinHeader,
            cardHeader,
            faceHeader,
            pinData,
            cardData,
            faceData,
            pinAction,
            cardAction,
            faceAction,
            updateAccessType,
            resetPw,
            goEditInfo,
            code,
            setPinCardType,
            isShow,
            setPinCardId,
            addAccess,
            editPin,
            editCard,
            accessGroupHeaders,
            accessGroupEdit,
            accessGroupData,
            selectAccessData,
            selfAccessHeaders,
            selfAccessEdit,
            fileEle,
            chooseFace,
            addFace,
            isShowSelfAccess,
            devices,
            deviceHeader,
            officeData,
            updateDevice,
            isShowResetBtn,
            setAllImg,
            community,
            floor,
            AccessFloor,
            isShowEditAccessibleFloors,
            initInfo
        };
    }
});
