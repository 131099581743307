
import {
    computed, defineComponent, watch, ref,
    Ref
} from 'vue';
import { community, phoneCode } from '@/data';
import { isCNServer } from '@/util/location';
import Notice from '@/util/notice.base';
import { getPhoneCodeOps } from '@/util/phone-code';
import initForm from './basic-form';

export default defineComponent({
    props: {
        commit: {
            type: Number,
            default: 0
        },
        id: {
            type: String
        }
    },
    setup(props, { emit }) {
        const formEle: Ref<any> = ref();
        const isEnableLiadline = computed(() => community.IsEnableLandline.value);

        const {
            buildOptions,
            aptOptions,
            basicData,
            accountType,
            rules
        } = initForm(props.id);

        let buildName = '';
        let aptName = '';

        watch(() => basicData.RoomID, () => {
            emit('roomChange', basicData.RoomID);
        });

        watch(computed(() => props.commit), () => {
            formEle.value.validate((valid: boolean) => {
                if (valid) {
                    buildOptions.value.forEach((element) => {
                        if (element.key === basicData.UnitID) buildName = element.value;
                    });
                    console.log(aptOptions);
                    let floor = '';
                    aptOptions.value.forEach((element) => {
                        if (element.key === basicData.RoomID) {
                            aptName = element.value;
                            floor = element.floor;
                        }
                    });
                    emit('submit', {
                        basicData,
                        buildName,
                        aptName,
                        accountType: accountType.value,
                        floor
                    });
                }
                return true;
            });
        });

        const limitMobileNumberType = (val: string) => {
            const reg = /[^\d]]*/g;
            basicData.MobileNumber = val.replace(reg, '');
        };

        return {
            rules,
            formEle,
            buildOptions,
            aptOptions,
            basicData,
            accountType,
            isEnableLiadline,
            isCNServer: isCNServer(),
            phoneCode,
            limitMobileNumberType,
            getPhoneCodeOps
        };
    }
});
