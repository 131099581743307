import index from './index.vue';
import { PeopleData } from './all.type';
import {
    enableLandLineCallType, unableLandLineCallType, sipCallOptions, defaultCallType
} from './util';

export default index;
export {
    PeopleData,
    enableLandLineCallType,
    unableLandLineCallType,
    sipCallOptions,
    defaultCallType
};