import { Ref, ref } from 'vue';
import { ListHeaderItem, ListActionItem } from '@/components/common/list';
import {
    account, access, getPhoneCodeNameTemp,
    office, accountType, community
} from '@/data';
import remove from '@/methods/remove-func';
import HttpRequest from '@/util/axios.config';
import router, { propertySubRouter, propertyBaseRouter } from '@/router';
import { residentApi } from '@/api';
import noticeBase from '@/util/notice.base';

const comSubUserRole = '21';
const AccessFloor = ref<string[]>([]);
const subUserEmail = ref('');

const getCommunityInfo = (id: string) => {
    const info: Ref<
    Array< {name: string; label: string; content: string} >
    > = ref([{
        name: 'Account',
        label: WordList.TabelPersonUserInHtmlUID,
        content: ''
    }, {
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName,
        content: ''
    }, {
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName,
        content: ''
    }, {
        name: 'RoomName',
        label: WordList.RDeviceSearchLabelRoomName,
        content: ''
    }, {
        name: 'AptName',
        label: WordList.RDeviceSearchLabelRoomNumber,
        content: ''
    }, {
        name: 'ActiveName',
        label: WordList.RDeviceListTanleActive,
        content: ''
    }, {
        name: 'ExpireTime',
        label: WordList.TabelExpretimeBoxSpecificTime,
        content: ''
    }, {
        name: 'InitializationName',
        label: WordList.ProperAllTextAppStatus,
        content: ''
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail,
        content: ''
    }, {
        name: 'PhoneCode',
        label: WordList.ProperAllTextCountryRegion,
        content: ''
    }, {
        name: 'MobileNumber',
        label: WordList.ProperAllTextMobileNumber,
        content: ''
    }]);

    const landLineInfo = {
        20: [{
            name: 'Phone',
            label: WordList.FirstLandline,
            content: ''
        }, {
            name: 'Phone2',
            label: WordList.SecondLandline,
            content: ''
        }, {
            name: 'Phone3',
            label: WordList.ThirdLandline,
            content: ''
        }],
        21: [{
            name: 'Phone',
            label: WordList.LandlineNumber,
            content: ''
        }]
    };

    const roomId = ref('');
    const officeData = ref('');
    const role = ref('');
    const isShowSelfAccess = ref(false);
    const isShowResetBtn = ref(false);
    account.getUserInfoForPM(id, (data: { [key in string]: string }) => {
        console.log('getUserInfoForPM');
        info.value = info.value.concat(landLineInfo[data.Role as ('20' | '21')]);
        info.value.forEach((item, index) => {
            if (item.name === 'PhoneCode') {
                info.value[index].content = getPhoneCodeNameTemp(data[item.name]) || '--';
            } else if (item.name === 'Email') {
                info.value[index].content = data[item.name] || '--';
                isShowResetBtn.value = !!data[item.name];
            } else {
                info.value[index].content = data[item.name] || '--';
            }
        });
        role.value = data.Role;
        if (role.value === comSubUserRole) {
            const mainUserEmail = data.MainUserEmail || '';
            const email = data.Email || '';
            isShowResetBtn.value = mainUserEmail !== '' || email !== '';
            subUserEmail.value = email;
        }
        roomId.value = data.RoomID;
        isShowSelfAccess.value = data.HasAccessDevice === '1';

        if (data.AccessFloor) {
            AccessFloor.value = data.AccessFloor === 'all' ? ['all'] : (data.AccessFloor as string).split(';');
        } else {
            AccessFloor.value = [];
        }
    });

    return {
        info,
        role,
        roomId,
        officeData,
        isShowSelfAccess,
        isShowResetBtn,
        AccessFloor
    };
};
const devices: Ref< string[] > = ref([]);
const updateDevice = ref(0);
// 落地呼叫选项
const enableLandLineOptions = [
    WordList.ProperAllTextSmartIndoorOrGuard,
    WordList.ProperAllTextPhoneIndoorOrGuard,
    WordList.ProperAllTextSmartPlusIndoorOrGuardBackup,
    WordList.ProperAllTextIndoorOrGuardSmartPlusBackup,
    WordList.ProperAllTextIndoorOrGuardPhoneBackup,
    WordList.ProperAllTextIndoorOrGuardSmartPlusPhone
];

const unableLandLineCallType = [
    WordList.ProperAllTextSmartIndoorOrGuard,
    '',
    '',
    WordList.ProperAllTextIndoorOrGuardSmartPlusBackup
];

const defaultCallType = [
    WordList.DefaultCallType
];

// 管理个人设备
const sipIPCallOptions = [
    WordList.ProperAllTextSIPCall,
    WordList.ProperAllTextIPCall
];

// 用户的呼叫权限
const controlOptions = [
    WordList.ProperAllTextOff,
    WordList.ProperAllTextOn
];
// 6.5.3办公梯控
const floor = ref<string[]>([]);

const getOfficeInfo = (id: string) => {
    const info: Ref<
    Array< {name: string; label: string; content: string; options?: Array< string >} >
    > = ref([{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName,
        content: ''
    }, {
        name: 'EmployeeID',
        label: WordList.ProperAllTextID,
        content: ''
    }, {
        name: 'DepartmentName',
        label: WordList.ProperAllTextDepartment,
        content: ''
    }, {
        name: 'ActiveName',
        label: WordList.RDeviceListTanleActive,
        content: ''
    }, {
        name: 'ExpireTime',
        label: WordList.TabelExpretimeBoxSpecificTime,
        content: ''
    }, {
        name: 'InitializationName',
        label: WordList.ProperAllTextAppStatus,
        content: ''
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail,
        content: ''
    }, {
        name: 'PhoneCode',
        label: WordList.ProperAllTextCountryRegion,
        content: ''
    }, {
        name: 'MobileNumber',
        label: WordList.ProperAllTextMobileNumber,
        content: ''
    }, {
        name: 'TempKeyPermission',
        label: WordList.ProperAllTextCreatQRCodes,
        content: '',
        options: controlOptions
    }, {
        name: 'Phone',
        label: WordList.LandlineNumber,
        content: ''
    }, {
        name: 'CallType',
        label: WordList.ProperAllTextCallType,
        content: '',
        options: defaultCallType
    }, {
        name: 'EnableIpDirect',
        label: WordList.ProperAllTextSipIpCall,
        content: '',
        options: sipIPCallOptions
    }]);

    const roomId = ref('');
    const officeData = ref('');
    const role = ref('');
    const isShowSelfAccess = ref(false);
    const isShowResetBtn = ref(false);
    office.getOwnerListDeviceOffice(id, (res: {data: []}) => {
        console.log(res);
        devices.value = res.data;
        updateDevice.value += 1;
    });
    office.getOfficeUserInfo(id, (res: {
        data: {
            details: accountType.InstallerOfficeUserInfo;
            devices: Array< any >;
            HasAccessDevice: string;
        };
    }) => {
        const { details } = res.data;

        // 63002：未开启落地时，隐藏落地号码项，64036：修复办公未开启落地且人员不带对讲，错误移除了Creating QR Codes字段
        if (String(details.HasIntercomAccess) === '0' || community.IsEnableLandline.value === 0) {
            info.value.splice(info.value.length - 3, 1);
        }
        info.value.forEach((item, index) => {
            const key = item.name as keyof accountType.InstallerOfficeUserInfo;
            if (item.name === 'PhoneCode' && key !== 'ID' && key !== 'DepartmentID' && key !== 'Floor') {
                info.value[index].content = getPhoneCodeNameTemp(details[key]) || '--';
            } else if (item.name === 'Email' && key !== 'Floor') {
                info.value[index].content = details[key] || '--';
                isShowResetBtn.value = !!details[key];
            } else if (item.options) {
                if (item.name === 'CallType') {
                    if (String(details.HasIntercomAccess) === '1') {
                        info.value[index].content = enableLandLineOptions[Number(details[key])];
                    } else {
                        info.value[index].content = defaultCallType[Number(details[key])];
                    }
                } else {
                    info.value[index].content = item.options[Number(details[key])];
                }
            } else if (key !== 'Floor') {
                info.value[index].content = details[key] || '--';
            }
        });
        role.value = details.Role;
        isShowSelfAccess.value = res.data.HasAccessDevice === '1';
        // devices.value = res.data.devices;
        officeData.value = `${details.DepartmentID};${details.Role}`;
        if (details.Floor) {
            floor.value = details.Floor === 'all' ? ['all'] : (details.Floor as string).split(';');
        } else {
            floor.value = [];
        }
    });

    return {
        info,
        role,
        roomId,
        officeData,
        isShowSelfAccess,
        isShowResetBtn,
        AccessFloor
    };
};

const resetPw = (id: string, role: string) => {
    if (role === comSubUserRole) {
        residentApi.resetSubUserPassword({
            ID: id
        }, () => {
            if (subUserEmail.value === '') {
                noticeBase.messageBox(
                    'alert',
                    WordList.ResetSubUserWithoutEmail,
                    WordList.Notice,
                    'success'
                )(() => false);
            }
        });
    } else {
        residentApi.resetUserPassword({
            ID: id
        }, () => false);
    }
};

const getAccess = (id: string) => {
    const secNavs = [{
        label: WordList.ProperAllTextPIN,
        key: 'pin'
    }, {
        label: WordList.DeliveryRfCard,
        key: 'card'
    }, {
        label: WordList.ProperAllTextFaceID,
        key: 'face'
    }];

    const activeKey = ref('pin');
    const changeActiveKey = (key: string) => {
        activeKey.value = key;
    };

    const pinHeader: Array< ListHeaderItem > = [{
        name: 'Code',
        label: WordList.ProperAllTextPIN
    }, {
        name: 'Creator',
        label: WordList.ProperAllTextCreatedBy,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];

    const cardHeader: Array< ListHeaderItem > = [{
        name: 'Code',
        label: WordList.DeliveryRfCard
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];

    const faceHeader: Array< ListHeaderItem > = [{
        name: 'Image',
        label: WordList.ProperAllTextFaceID,
        type: 'customize'
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];

    const pinData: Ref<object[]> = ref([]);
    const cardData: Ref<object[]> = ref([]);
    const faceData: Ref<object[]> = ref([]);
    const accessGroupData: Ref<object[]> = ref([]);
    const selectAccessData: Ref<object[]> = ref([]);
    const updateAccessType = ref(0);

    const getAccessInfo = () => {
        access.getPropertyAllUserAccess(id, (data: {
            PIN: object[];
            Card: object[];
            Face: object[];
            AccessGroup: object[];
            SelfAccess: { Device: Array<object> };
        }) => {
            pinData.value = data.PIN;
            cardData.value = data.Card;
            faceData.value = data.Face;
            accessGroupData.value = data.AccessGroup;
            selectAccessData.value = [data.SelfAccess];
            updateAccessType.value += 1;
        });
    };

    const fileEle = ref<any>();
    const addFace = () => {
        fileEle.value.click();
    };
    const chooseFace = () => {
        const file = fileEle.value.files[0];
        if (!/\.(?:jpg|png|bmp)$/.test(file.name)) {
            noticeBase.messageBox('alert', WordList.ImportFaceExtError, WordList.Notice, 'info')(() => false);
            return;
        }
        const param = new FormData();
        param.append('ID', id);
        param.append('Face', fileEle.value.files[0]);
        HttpRequest.post(`v3/web/${community.ProjectType.value}/face/addSingleFace`, param, () => {
            getAccessInfo();
            updateAccessType.value += 1;
            fileEle.value.value = '';
        }, () => {
            console.log('fail');
            fileEle.value.value = '';
        });
    };

    const pinAction: ListActionItem[] = [{
        type: 'edit2',
        event: 'edit',
        class: 'el-icon-my-modify modify-icon',
        showCondition(data: { Special: string }) {
            return data.Special === '0';
        },
        title: WordList.TabelConfigInHtmlModify
    }, {
        type: 'delete2',
        event(data: { ID: string }) {
            remove(WordList.ProperAllTextDeletePinTip, () => {
                HttpRequest.post(`v3/web/${community.ProjectType.value}/key/delUserPin`, {
                    ID: data.ID
                }, () => {
                    getAccessInfo();
                });
            });
        },
        class: 'el-icon-delete delete-icon',
        showCondition(data: { Special: string }) {
            return data.Special === '0';
        },
        title: WordList.TabelConfigInHtmlDelete
    }];

    const cardAction: ListActionItem[] = [{
        type: 'edit2',
        event: 'edit',
        class: 'el-icon-my-modify modify-icon',
        showCondition(data: { IsCreateByPm: '0' | '1' }) {
            return data.IsCreateByPm === '1';
        },
        title: WordList.TabelConfigInHtmlModify
    }, {
        type: 'delete2',
        event(data: { ID: string }) {
            remove(WordList.ProperAllTextDeleteCardTip, () => {
                HttpRequest.post(`v3/web/${community.ProjectType.value}/key/delUserRfCard`, {
                    ID: data.ID
                }, () => {
                    getAccessInfo();
                });
            });
        },
        class: 'el-icon-delete delete-icon',
        showCondition(data: { IsCreateByPm: '0' | '1' }) {
            return data.IsCreateByPm === '1';
        },
        title: WordList.TabelConfigInHtmlDelete
    }];

    const faceAction: ListActionItem[] = [{
        type: 'edit2',
        event: 'edit',
        class: 'el-icon-my-modify modify-icon',
        title: WordList.TabelConfigInHtmlModify
    }, {
        type: 'delete2',
        event(data: { ID: string }) {
            remove(WordList.ProperAllTextDeleteFaceTip, () => {
                HttpRequest.post(`v3/web/${community.ProjectType.value}/face/del`, {
                    ID: data.ID
                }, () => {
                    updateAccessType.value += 1;
                    getAccessInfo();
                });
            });
        },
        class: 'el-icon-delete delete-icon',
        title: WordList.TabelConfigInHtmlDelete
    }];

    const accessGroupHeaders: ListHeaderItem[] = [
        {
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName
        }, {
            name: 'Location',
            label: WordList.TabelUpdateBoxDevice
        }, {
            name: 'Repeat',
            label: WordList.ProperAllTextRepeats,
            type: 'customize'
        }, {
            name: 'Time',
            label: WordList.ProperAllTextTime,
            type: 'customize'
        }
    ];

    const accessGroupEdit = (roomId: string, officeData: string) => {
        // 办公获取权限组增加参数DepartmentID、Role
        if (officeData !== '') {
            const departmentId = officeData.split(';')[0];
            const role = officeData.split(';')[1];
            router.push(`/${propertyBaseRouter}/${propertySubRouter.residentSetAccessGroup}?departmentId=${departmentId}&role=${role}&id=${id}`);
        } else {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.residentSetAccessGroup}?roomId=${roomId}&id=${id}`);
        }
    };

    const selfAccessHeaders: ListHeaderItem[] = [
        {
            name: 'Repeat',
            label: WordList.ProperAllTextRepeats,
            type: 'customize'
        }, {
            name: 'Time',
            label: WordList.ProperAllTextTime,
            type: 'customize'
        }
    ];

    const selfAccessEdit = (roomId: string, pid: string | false) => {
        // 办公获取权限组增加参数DepartmentID、Role
        if (pid) {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.residentSetSelfAccess}?pid=${pid}&id=${id}`);
        } else {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.residentSetSelfAccess}?roomId=${roomId}&id=${id}`);
        }
    };

    getAccessInfo();

    return {
        secNavs,
        activeKey,
        changeActiveKey,
        getAccessInfo,
        pinHeader,
        cardHeader,
        faceHeader,
        pinData,
        cardData,
        faceData,
        updateAccessType,
        pinAction,
        cardAction,
        faceAction,
        accessGroupHeaders,
        accessGroupEdit,
        accessGroupData,
        selectAccessData,
        selfAccessHeaders,
        selfAccessEdit,
        fileEle,
        addFace,
        chooseFace
    };
};

const initSetPinCard = (id: string) => {
    const code = ref('');
    const isShow = ref(false);
    const type = ref('addPin');
    const setPinCardId = ref(id);

    const addPin = () => {
        code.value = '';
        isShow.value = true;
        type.value = 'addPin';
        setPinCardId.value = id;
    };

    const addCard = () => {
        code.value = '';
        isShow.value = true;
        type.value = 'addCard';
        setPinCardId.value = id;
    };

    const editPin = (data: { ID: string; Code: string }) => {
        code.value = data.Code;
        isShow.value = true;
        type.value = 'editPin';
        setPinCardId.value = data.ID;
    };

    const editCard = (data: { ID: string; Code: string }) => {
        code.value = data.Code;
        isShow.value = true;
        type.value = 'editCard';
        setPinCardId.value = data.ID;
    };

    return {
        code,
        isShow,
        type,
        setPinCardId,
        addPin,
        addCard,
        editPin,
        editCard
    };
};

const getInfo = (id: string, projectType: ProjectType) => {
    if (projectType === 'office') {
        return getOfficeInfo(id);
    }
    return getCommunityInfo(id);
};
const initInfo = (id: string, projectType: ProjectType) => {
    const {
        info,
        roomId,
        role,
        isShowSelfAccess,
        officeData,
        isShowResetBtn,
        AccessFloor
    } = getInfo(id, projectType);
    const {
        secNavs,
        activeKey,
        changeActiveKey,
        getAccessInfo,
        pinHeader,
        cardHeader,
        faceHeader,
        pinData,
        cardData,
        faceData,
        updateAccessType,
        pinAction,
        cardAction,
        faceAction,
        accessGroupHeaders,
        accessGroupEdit,
        accessGroupData,
        selectAccessData,
        selfAccessHeaders,
        selfAccessEdit,
        fileEle,
        addFace,
        chooseFace
    } = getAccess(id);
    return {
        info,
        role,
        roomId,
        secNavs,
        activeKey,
        changeActiveKey,
        getAccessInfo,
        pinHeader,
        cardHeader,
        faceHeader,
        pinData,
        cardData,
        faceData,
        updateAccessType,
        resetPw,
        pinAction,
        cardAction,
        faceAction,
        accessGroupHeaders,
        accessGroupEdit,
        accessGroupData,
        selectAccessData,
        selfAccessHeaders,
        selfAccessEdit,
        fileEle,
        addFace,
        chooseFace,
        isShowSelfAccess,
        officeData,
        isShowResetBtn,
        floor,
        AccessFloor
    };
};

const getDeviceData = () => {
    const deviceHeader: Array< ListHeaderItem > = [{
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }];

    return {
        deviceHeader,
        devices,
        updateDevice
    };
};

export default null;
export {
    initInfo,
    initSetPinCard,
    getDeviceData
};
