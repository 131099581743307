import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pin_encrypt_input = _resolveComponent("pin-encrypt-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.title,
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        "label-position": _ctx.$formLabelPosition,
        ref: "formEle",
        model: _ctx.formData,
        rules: _ctx.rules,
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [
          (_ctx.type == 'addPin' || _ctx.type == 'editPin')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: _ctx.$wordList.ProperAllTextPIN,
                prop: "Code"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_pin_encrypt_input, {
                    modelValue: _ctx.formData.Code,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.Code = $event)),
                    option: _ctx.type == 'addPin' ? 'add' : 'edit'
                  }, null, 8, ["modelValue", "option"])
                ]),
                _: 1
              }, 8, ["label"]))
            : (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: _ctx.$wordList.KeyAndCardRadioButtonRFCards,
                prop: "Code"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    type: "text",
                    modelValue: _ctx.formData.Code,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.Code = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]))
        ]),
        _: 1
      }, 8, ["label-position", "model", "rules"])
    ]),
    _: 1
  }, 8, ["title", "onSubmit"]))
}