
import {
    defineComponent, PropType, reactive, Ref, ref, computed
} from 'vue';
import { community } from '@/data';
import { access } from '@/methods/rule';
import pinEncryptInput from '@/components/common/pin-encrypt-input/';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    components: {
        pinEncryptInput
    },
    props: {
        code: {
            required: true,
            type: String
        },
        id: {
            required: true,
            type: String
        },
        type: {
            required: true,
            type: String as PropType<'addPin' | 'addCard' | 'editPin' | 'editCard'>
        }
    },
    setup(props, { emit }) {
        const formEle: Ref<any> = ref();
        const formData = reactive({
            Code: props.code,
            ID: props.id
        });

        const valids = {
            addPin: computed(() => access.checkPin(false, 'resident')),
            editPin: computed(() => access.checkPin(community.IsEncryptPin.value === '1', 'resident')),
            addCard: access.checkCard,
            editCard: access.checkCard
        };

        const rules = reactive({
            Code: [{
                required: true,
                message: WordList.RuleKeyInvalid,
                trigger: 'change'
            }, {
                validator: valids[props.type],
                trigger: 'blur'
            }]
        });

        const title = {
            addPin: WordList.DeliveryAddPKey,
            addCard: WordList.DeliveryAddRCard,
            editPin: WordList.ProperAllTextEditPin,
            editCard: WordList.DeliveryEditRCard
        }[props.type];
        const urls = {
            addPin: `v3/web/${community.ProjectType.value}/key/addUserPin`,
            addCard: `v3/web/${community.ProjectType.value}/key/addUserRfCard`,
            editPin: `v3/web/${community.ProjectType.value}/key/editUserPin`,
            editCard: `v3/web/${community.ProjectType.value}/key/editUserRfCard`
        };

        const submit = () => {
            formEle.value.validate((valid: boolean) => {
                if (valid) {
                    HttpRequest.post(urls[props.type], formData, () => {
                        emit('success');
                        emit('close');
                    });
                }
            });
        };

        return {
            formEle,
            formData,
            rules,
            title,
            submit,
            isEncryptPin: community.IsEncryptPin
        };
    }
});
