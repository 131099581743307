
import {
    defineComponent, PropType, reactive, ref
} from 'vue';
import residentApi from '@/api/pm/resident';

export default defineComponent({
    emits: ['close', 'success'],
    props: {
        initFloors: {
            type: Object as PropType<string[]>,
            required: true
        },
        floorKey: {
            type: String,
            default: 'Floor'
        },
        apiKey: {
            type: String as PropType<'editAccessFloor'|'editComAccessFloor'>,
            default: 'editAccessFloor'
        },
        id: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        // 楼层数据
        const floorOptions = ref<{key: string;value: string}[]>([{
            key: 'all',
            value: WordList.AllFloors
        }]);
        for (let i = 1; i < 129; i += 1) {
            floorOptions.value.push({
                key: i.toString(),
                value: i.toString()
            });
        }
        const formData = reactive({
            Floor: [...props.initFloors]
        });

        // 最多只能选10个floor层
        function changeFloor() {
            if (formData.Floor.length > 10) {
                formData.Floor.pop();
            }
        }

        function submit() {
            const data: {[index: string]: any} = {
                ID: props.id
            };
            data[props.floorKey] = formData.Floor.includes('all') ? 'all' : formData.Floor.join(';');
            residentApi[props.apiKey]({
                ...data
            }, () => {
                emit('close');
                emit('success');
            });
        }

        return {
            formData,
            floorOptions,
            changeFloor,
            submit
        };
    }
});
