
import {
    computed, defineComponent, watch, ref,
    Ref
} from 'vue';
import { phoneCode, community, account } from '@/data';
import { isCNServer } from '@/util/location';
import { getPhoneCodeOps } from '@/util/phone-code';
import Notice from '@/util/notice.base';

import {
    enableLandLineCallType,
    unableLandLineCallType,
    sipCallOptions,
    defaultCallType
} from '@/components/view/office/people-set-dialog';
import { officeInit } from './basic-form';

export default defineComponent({
    props: {
        commit: {
            type: Number,
            default: 0
        },
        id: {
            type: String
        }
    },
    setup(props, { emit }) {
        const formEle: Ref<any> = ref();
        const EnableLandLine = community.IsEnableLandline;
        const callTypeOptions: Ref< Array<{
            label: string;
            value: string;
        }> > = ref([]);

        const {
            basicData,
            rules,
            buildOptions
        } = officeInit(props.id);

        watch([community.IsEnableLandline, basicData], () => {
            if (basicData.HasIntercomAccess === '0') {
                callTypeOptions.value = defaultCallType;
            } else {
                callTypeOptions.value = community.IsEnableLandline.value === 1 ? enableLandLineCallType : unableLandLineCallType;
            }
        }, {
            immediate: true
        });

        let departmentName = '';

        // 保存当前部门的floor配置
        const floor = ref('');
        function saveFloor(value: string) {
            floor.value = '';
            buildOptions.value.forEach((item) => {
                if (value === item.key && item.floor) {
                    floor.value = item.floor;
                }
            });
        }

        watch(computed(() => props.commit), () => {
            formEle.value.validate((valid: boolean) => {
                if (valid) {
                    if (Number(basicData.Role) === account.personnelRole && !basicData.Email && !basicData.MobileNumber) {
                        Notice.messageBox('alert', WordList.ProperAllTextEmailMobileFillIn, WordList.PersonuserInfo, 'info')(() => false);
                        return false;
                    }
                    buildOptions.value.forEach((element) => {
                        if (element.key === basicData.DepartmentID) departmentName = element.value;
                    });
                    basicData.TempKeyPermission = String(basicData.TempKeyPermission);
                    emit('submit', {
                        basicData,
                        departmentName,
                        employeeID: basicData.EmployeeID,
                        floor: floor.value
                    });
                }
                return true;
            });
        });

        const limitMobileNumberType = (val: string) => {
            const reg = /[^\d]]*/g;
            basicData.MobileNumber = val.replace(reg, '');
        };

        const isShowExpireTip = ref(false);
        const changeUserQRCode = () => {
            // 1.不存在高级功能，只显示on 2.过期且切换到off，显示提示语且无法切换 3.其余情况正常切换
            if (community.LimitQRCode.value === '0') {
                Notice.messageBox('alert', WordList.ProperAllTextactivateFeatureTips, WordList.PersonuserInfo, 'warning')(() => false);
                basicData.TempKeyPermission = '1';
            } else if (community.LimitQRCode.value === '2' && basicData.TempKeyPermission === '0') {
                basicData.TempKeyPermission = '1';
                isShowExpireTip.value = true;
            } else {
                isShowExpireTip.value = false;
            }
        };

        return {
            rules,
            formEle,
            basicData,
            isCNServer: isCNServer(),
            phoneCode,
            buildOptions,
            limitMobileNumberType,
            EnableLandLine,
            callTypeOptions,
            sipCallOptions,
            isShowExpireTip,
            changeUserQRCode,
            saveFloor,
            getPhoneCodeOps
        };
    }
});
