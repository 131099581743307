import form from './form.vue';
import officeForm from './office-form.vue';
import setPinCard from './set-pin-card.vue';
import { FormData } from './basic-form';

export default null;
export {
    form,
    FormData,
    setPinCard,
    officeForm
};